<template>
  <BaseLink
    :class="social"
    class="button typography__body2"
    :href="url ?? `https://api-${project}.mydonut.app/auth/${social}?redirect_to=${redirectTo}`"
  >
    <BaseIcon2
      :id="`icon-${SOCIALS[social].icon}`"
      class="button-icon"
      sprite="socials"
    />

    {{ SOCIALS[social].name }}
  </BaseLink>
</template>

<script lang="ts" setup>
type Social = "google" | "mailru" | "telegram" | "vk" | "yandex";

type Project = "crm" | "cv";

interface SocialParameters {
  name: string;
}

interface Props {
  project: Project;
  redirectTo: string;
  social: Social;
  url?: string;
}

defineProps<Props>();

const SOCIALS: Record<Social, SocialParameters> = {
  google: {
    icon: "google",
    name: "Google",
  },
  mailru: {
    icon: "mailru-blue",
    name: "Почта Mail",
  },
  telegram: {
    icon: "telegram",
    name: "Telegram",
  },
  vk: {
    icon: 'vk',
    name: "ВКонтакте",
  },
  yandex: {
    icon: 'yandex',
    name: "Яндекс",
  },
};
</script>

<style scoped>
.button {
  display: flex;
  column-gap: 8px;
  padding: 8px 24px;
  border-radius: 8px;
  background-color: var(--grey-7);
}

.button.mailru {
  background-color: #0069fe;
  color: var(--white);
}

.button-icon {
  width: 24px;
  height: 24px;
}
</style>
